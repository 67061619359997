import { Flex } from "@chakra-ui/react";
import { ConnectWallet } from "@thirdweb-dev/react";
import { FC } from "react";
import { useLogEvent } from "src/lib/tracking";
import { useWallet } from "@thirdweb-dev/react";

type ConnectButtonsProps = {
  hideShadow?: boolean;
};

export const ConnectButtons: FC<ConnectButtonsProps> = () => {
  // TODO: is this needed as only resolves to metamask and walletconnect?
  const walletInstance = useWallet();
  const wallet = walletInstance?.walletId;
  useLogEvent(
    {
      action: "connect_wallets",
      payload: { wallet },
    },
    [wallet]
  );

  return (
    <Flex gap={6} alignItems="center" justifyContent="center" h="100%">
      <ConnectWallet />
    </Flex>
  );
};
