import { useDisconnect } from "@thirdweb-dev/react";
import { useRouter } from "next/router";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { LensProfileFragment } from "src/gql/generated";
import { unawaited } from "src/lib/unawaited";
import { useCurrentUserContext } from "src/providers/UserProvider";
import { Step } from "../pages/sign-in-lens";

type SignInLensContextParams = {
  step: Step;
  setStep: Dispatch<SetStateAction<Step>>;
  lensProfile?: LensProfileFragment | null;
  setLensProfile: Dispatch<SetStateAction<LensProfileFragment | null>>;
  reset: () => void;
};
const SignInLensContext = createContext<SignInLensContextParams | undefined>(
  undefined
);

export const SignInLensProvider: FC<PropsWithChildren> = ({ children }) => {
  const disconnect = useDisconnect();
  const router = useRouter();
  const user = useCurrentUserContext();
  const [lensProfile, setLensProfile] = useState<LensProfileFragment | null>(
    user.profile.lensProfile || null
  );
  const [step, setStep] = useState<Step>(Step.INITIAL);
  const reset = () => {
    setLensProfile(null);
    setStep(Step.INITIAL);
    unawaited(disconnect().then(() => router.replace("/")));
  };
  return (
    <SignInLensContext.Provider
      value={{ step, setStep, lensProfile, setLensProfile, reset }}
    >
      {children}
    </SignInLensContext.Provider>
  );
};

export const useSignInLensContext = () => {
  const ctx = useContext(SignInLensContext);
  if (!ctx)
    throw Error(
      "useSignInLensContext must be used within a SignInLensProvider."
    );
  return ctx;
};
