import { Grid, VStack, Box } from "@chakra-ui/react";
import { useAddress } from "@thirdweb-dev/react";
import { useTranslation } from "next-i18next";
import { FC, useEffect } from "react";
import { Button } from "src/components/atoms/Button";
import { Image } from "src/components/atoms/Image";
import { Txt } from "src/components/atoms/Txt";
import { useSignInLensContext } from "src/contexts/SignInLensContext";
import { useLogEvent } from "src/lib/tracking";
import { Step } from "src/pages/sign-in-lens";
import { ConnectButtons } from "src/components/organisms/ConnectButtons";

export const ConnectWalletStep: FC = () => {
  useLogEvent({ action: "connect_wallet_step" });
  const address = useAddress();
  const { setStep } = useSignInLensContext();
  useEffect(() => {
    if (address) {
      setStep(Step.SELECT_LENS_PROFILE);
    }
  }, [address, setStep]);
  const { t } = useTranslation();
  return (
    <>
      <Grid p={6} justifyItems="center" gap={6} maxW="600px" mx="auto">
        <Image
          width={172}
          height={172}
          src="/images/lens-logo.png"
          alt="Lens Logo"
        />
        <Txt textStyle="h2">{t("SignInLens.ConnectWithYourWalletTitle")}</Txt>
        <Txt textStyle="p2" color="white">
          {t("SignInLens.ConnectWithYourWalletDescription")}
        </Txt>
        <Box p={6}>
          <ConnectButtons hideShadow />
        </Box>
      </Grid>
      <VStack
        position={{ base: "sticky", md: "absolute" }}
        bottom={0}
        left={0}
        right={0}
        bg="componentBg"
        py={3}
      >
        <Button
          isDisabled={!address}
          onClick={() => setStep(Step.CONNECT_WALLET)}
        >
          {t("Continue")}
        </Button>
      </VStack>
    </>
  );
};
