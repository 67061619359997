import {
  ChainId,
  useAddress,
  useChainId,
  useNetwork
} from "@thirdweb-dev/react";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Txt } from "src/components/atoms/Txt";
import { unawaited } from "src/lib/unawaited";
import { isMobile } from "src/utils/isMobile";
import { Button } from "../atoms/Button";

type SelectedChainDisclaimerProps = {
  expectectedChainId: ChainId
}

export const SelectedChainDisclaimer: FC<SelectedChainDisclaimerProps> = ({ expectectedChainId }) => {
  const { t } = useTranslation();
  const [, switchNetwork] = useNetwork();
  const chainId = useChainId();
  const address = useAddress();
  if (!chainId || !address)
    return null;

  const isRightChainId = expectectedChainId === chainId

  if (!isRightChainId) {
    return (
      <>
        {switchNetwork && (
          <Button
            onClick={() => {
              unawaited(switchNetwork(expectectedChainId));
            }}
          >
            {t("AccountAddressBox.SwitchNetworkButtonText")}
          </Button>
        )}
        <Txt textStyle="p2" color="red">
          {t("AccountAddressBox.SelectNetworkToContinue", {
            chainId: ChainId[expectectedChainId],
          })}
        </Txt>
      </>
    );
  }

  // Hide as in app browsers of wallets open all pages in same view which causes auth session to disappear
  if (isMobile())
    return null;

  return null;
};
