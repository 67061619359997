import { Grid, Icon } from "@chakra-ui/react";
import { ChainId, useAddress } from "@thirdweb-dev/react";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { Link } from "src/components/atoms/Link";
import { Txt } from "src/components/atoms/Txt";
import { NEXT_PUBLIC_LENS_POLYGONSCAN_URL } from "src/config";
import { SelectedChainDisclaimer } from "./SelectedChainDisclaimer";

type AccountAddressBoxProps = { expectectedChainId?: ChainId };

export const AccountAddressBox: FC<AccountAddressBoxProps> = ({
  expectectedChainId,
}) => {
  const { t } = useTranslation();
  const address = useAddress();
  if (!address) return null;
  const href = `${NEXT_PUBLIC_LENS_POLYGONSCAN_URL}address/${address}`;

  return (
    <Grid
      pos="relative"
      px={6}
      py={3}
      gap={4}
      rounded="xl"
      alignItems="center"
      bg="headerGlassEffect"
    >
      <Txt textStyle="h3">{t("AccountAddressBox.Title")}</Txt>
      <Link
        target="_blank"
        textStyle="p2"
        color="lightGray"
        textAlign="center"
        href={href}
      >
        <Txt textStyle="p2" color="lightGray">
          {address}
        </Txt>
      </Link>
      <Icon
        pos="absolute"
        top={4}
        right={4}
        color="white"
        as={BsBoxArrowUpRight}
      />
      {expectectedChainId && (
        <SelectedChainDisclaimer expectectedChainId={expectectedChainId} />
      )}
    </Grid>
  );
};
