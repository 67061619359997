import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { useSigner } from "@thirdweb-dev/react";
import { useState } from "react";
import { NEXT_PUBLIC_DISPATCHER_TYPE } from "src/config";
import {
  CurrentUserDocument,
  DispatcherTypes,
  LensSetDispatcherBuildDataDocument,
  LensSetDispatcherBuildDataQuery,
  LensSetDispatcherBuildDataQueryVariables,
  LensSetDispatcherExecuteDocument,
  LensSetDispatcherExecuteMutation,
  LensSetDispatcherExecuteMutationVariables,
} from "src/gql/generated";
import { signTypedDataInternal } from "src/utils/signTypedDataInternal";
import { safeParse } from "src/utils/safeParse";
import { toError } from "src/utils/toError";
import * as z from "zod";

type SetDispatcherFunctionParam = {
  lensProfileId: string;
};

const eip712DomainSchema = z.object({
  name: z.string(),
  type: z.string(),
});

const buildDataMessageSchema = z.object({
  types: z.object({
    EIP712Domain: eip712DomainSchema.array(),
    ChangeDelegatedExecutorsConfig: eip712DomainSchema.array(),
  }),
  primaryType: z.string(),
  domain: z.object({
    name: z.string(),
    version: z.string(),
    chainId: z.number(),
    verifyingContract: z.string(),
  }),
  message: z.object({
    delegatorProfileId: z.string(),
    delegatedExecutors: z.string().array(),
    approvals: z.boolean().array(),
    configNumber: z.string(),
    switchToGivenConfig: z.boolean(),
    nonce: z.number(),
    deadline: z.string(),
  }),
});

type UseSetDispatcherState = {
  loading: boolean;
  loadingStep?: "build" | "sign" | "execute";
  data?: LensSetDispatcherExecuteMutation;
  error?: Error;
};

export const useSetDispatcher = () => {
  const signer = useSigner();
  const client = useApolloClient();
  const [state, setState] = useState<UseSetDispatcherState>({
    loading: false,
  });

  const setDispatcher = async ({
    lensProfileId,
  }: SetDispatcherFunctionParam) => {
    setState({ loading: true, loadingStep: "build" });
    try {
      const { data: buildData } = await client.query<
        LensSetDispatcherBuildDataQuery,
        LensSetDispatcherBuildDataQueryVariables
      >({
        query: LensSetDispatcherBuildDataDocument,
        variables: {
          lensProfileId,
          dispatcher: DispatcherTypes[NEXT_PUBLIC_DISPATCHER_TYPE],
        },
        fetchPolicy: "no-cache",
      });

      const { domain, types, message } = buildDataMessageSchema.parse(
        safeParse(buildData.lensSetDispatcherBuildData.message)
      );

      if (!signer) throw new Error("signer missing");

      setState({ loading: true, loadingStep: "sign" });

      const { signature: sig } = await signTypedDataInternal(
        signer,
        domain,
        {
          ChangeDelegatedExecutorsConfig: types.ChangeDelegatedExecutorsConfig,
        },
        message
      );

      setState({
        loading: true,
        loadingStep: "execute",
      });
      const result = await client.mutate<
        LensSetDispatcherExecuteMutation,
        LensSetDispatcherExecuteMutationVariables
      >({
        mutation: LensSetDispatcherExecuteDocument,
        variables: { lensProfileId, sig },
        fetchPolicy: "no-cache",
        refetchQueries: [{ query: CurrentUserDocument }],
      });

      if (!result.data) {
        throw new Error("no data in result");
      }
      setState({ loading: false, data: result.data });
    } catch (error) {
      Sentry.captureException(error);
      setState({ loading: false, error: toError(error) });
    }
  };

  return { setDispatcher, ...state };
};
