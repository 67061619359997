/* eslint-disable node/no-process-env */
import { Polygon } from "@thirdweb-dev/chains";
import { from } from "env-var";

// need to define envs separately https://nextjs.org/docs/basic-features/environment-variables#exposing-environment-variables-to-the-browser
// prettier-ignore
const env = from({
  NEXT_PUBLIC_PHAVER_IMG_BUCKET: process.env.NEXT_PUBLIC_PHAVER_IMG_BUCKET,
  NEXT_PUBLIC_PHAVER_IMG_DEV_BUCKET: process.env.NEXT_PUBLIC_PHAVER_IMG_DEV_BUCKET,
  NEXT_PUBLIC_PHAVER_IMGPROXY_BASE_URL: process.env.NEXT_PUBLIC_PHAVER_IMGPROXY_BASE_URL,
  NEXT_PUBLIC_PHAVER_IMGPROXY_DEV_BASE_URL: process.env.NEXT_PUBLIC_PHAVER_IMGPROXY_DEV_BASE_URL,
  NEXT_PUBLIC_GQL_ENDPOINT: process.env.NEXT_PUBLIC_GQL_ENDPOINT,
  NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  NEXT_PUBLIC_CHAIN_ID: process.env.NEXT_PUBLIC_CHAIN_ID,
  NEXT_PUBLIC_RPC_URL: process.env.NEXT_PUBLIC_RPC_URL,
  NEXT_PUBLIC_LENS_POLYGONSCAN_URL: process.env.NEXT_PUBLIC_LENS_POLYGONSCAN_URL,
  NEXT_PUBLIC_PHAVER_DISCORD_URL: process.env.NEXT_PUBLIC_PHAVER_DISCORD_URL,
  NEXT_PUBLIC_PHAVER_ENV: process.env.NEXT_PUBLIC_PHAVER_ENV,
  NEXT_PUBLIC_CONNECT_DEV_TOOLS: process.env.NEXT_PUBLIC_CONNECT_DEV_TOOLS,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SENTRY_ENABLED: process.env.NEXT_PUBLIC_SENTRY_ENABLED,
  NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE: process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE,
  NEXT_PUBLIC_PHAVER_API_BASE_URL: process.env.NEXT_PUBLIC_PHAVER_API_BASE_URL,
  NEXT_PUBLIC_GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
  NEXT_PUBLIC_DISPATCHER_TYPE: process.env.NEXT_PUBLIC_DISPATCHER_TYPE,
  NEXT_PUBLIC_FIREBASE_APP_CHECK_ENABLED: process.env.NEXT_PUBLIC_FIREBASE_APP_CHECK_ENABLED,
  NEXT_PUBLIC_FIREBASE_APP_CHECK_DEBUG_TOKEN: process.env.NEXT_PUBLIC_FIREBASE_APP_CHECK_DEBUG_TOKEN,
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  NEXT_PUBLIC_FIREBASE_APP_CHECK_TOKEN_HEADER_ENABLED: process.env.NEXT_PUBLIC_FIREBASE_APP_CHECK_TOKEN_HEADER_ENABLED,
});

export const NEXT_PUBLIC_PHAVER_IMG_BUCKET = env
  .get("NEXT_PUBLIC_PHAVER_IMG_BUCKET")
  .default("phaver-assets")
  .asString();

export const NEXT_PUBLIC_PHAVER_IMG_DEV_BUCKET = env
  .get("NEXT_PUBLIC_PHAVER_IMG_DEV_BUCKET")
  .default("phavertest")
  .asString();

export const NEXT_PUBLIC_PHAVER_IMGPROXY_BASE_URL = env
  .get("NEXT_PUBLIC_PHAVER_IMGPROXY_BASE_URL")
  .default("https://img.next.phaver.com/")
  .asUrlString();

export const NEXT_PUBLIC_PHAVER_IMGPROXY_DEV_BASE_URL = env
  .get("NEXT_PUBLIC_PHAVER_IMGPROXY_DEV_BASE_URL")
  .default("https://img.dev.next.phaver.com")
  .asUrlString();

export const NEXT_PUBLIC_GQL_ENDPOINT = env
  .get("NEXT_PUBLIC_GQL_ENDPOINT")
  .default("https://gql.dev.next.phaver.com/v1/graphql")
  .asUrlString();

export const NEXT_PUBLIC_FIREBASE_API_KEY = env
  .get("NEXT_PUBLIC_FIREBASE_API_KEY")
  .default("AIzaSyCJ14nQAuih_w_WORv4Z1h-flyp4NRKIfk")
  .asString();

export const NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN = env
  .get("NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN")
  .default("phaver-alpha.firebaseapp.com")
  .asString();

export const NEXT_PUBLIC_FIREBASE_PROJECT_ID = env
  .get("NEXT_PUBLIC_FIREBASE_PROJECT_ID")
  .default("phaver-alpha")
  .asString();

export const NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID = env
  .get("NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID")
  .default("691599731729")
  .asString();

export const NEXT_PUBLIC_FIREBASE_APP_ID = env
  .get("NEXT_PUBLIC_FIREBASE_APP_ID")
  .default("1:691599731729:web:58e4dfe17abd7407c3d5b0")
  .asString();

export const NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID = env
  .get("NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID")
  .default("G-EKZSSLCF48")
  .asString();

export const NEXT_PUBLIC_CHAIN_ID = env
  .get("NEXT_PUBLIC_CHAIN_ID")
  .default(Polygon.chainId)
  .asInt();

export const NEXT_PUBLIC_RPC_URL = env
  .get("NEXT_PUBLIC_RPC_URL")
  .default("https://mainnet.infura.io/v3")
  .asUrlString();

export const NEXT_PUBLIC_LENS_POLYGONSCAN_URL = env
  .get("NEXT_PUBLIC_LENS_POLYGONSCAN_URL")
  .default("https://mumbai.polygonscan.com/")
  .asUrlString();

export const NEXT_PUBLIC_PHAVER_DISCORD_URL = env
  .get("NEXT_PUBLIC_PHAVER_DISCORD_URL")
  .default("https://discord.gg/phaver")
  .asUrlString();

export const NEXT_PUBLIC_PHAVER_ENV = env
  .get("NEXT_PUBLIC_PHAVER_ENV")
  .default("dev")
  .asEnum(["dev", "stg", "prod"]);

export const NEXT_PUBLIC_CONNECT_DEV_TOOLS = env
  .get("NEXT_PUBLIC_CONNECT_DEV_TOOLS")
  .default("false")
  .asBool();

export const NEXT_PUBLIC_SENTRY_DSN = env
  .get("NEXT_PUBLIC_SENTRY_DSN")
  .default(
    "https://e2df90f288be46fe90ba623939ba8cee@o322179.ingest.sentry.io/6593483"
  )
  .asUrlString();

export const NEXT_PUBLIC_SENTRY_ENABLED = env
  .get("NEXT_PUBLIC_SENTRY_ENABLED")
  .default("false")
  .asBool();

export const NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE = env
  .get("NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE")
  .default("1.0")
  .asFloat();

export const CLAIM_LENS_INSTRUCTIONS_URL =
  "https://phaver.notion.site/Claiming-Profile-NFT-038d0795d751474fb7734abb69a863b2";

export const NEXT_PUBLIC_PHAVER_API_BASE_URL = env
  .get("NEXT_PUBLIC_PHAVER_API_BASE_URL")
  .default("https://api.dev.next.phaver.com/api")
  .asUrlString();

export const METAMASK_LOGIN_GUIDE_URL =
  "https://phaver.gitbook.io/phaver-help-center/using-phaver/changing-login-details-to-login-to-the-lens-api";

export const PROXY_CONNECT_GUIDE_URL =
  "https://phaver.gitbook.io/phaver-help-center/managing-your-profile/connect-nfts-safely";

export const PHAVER_TELEGRAM_URL = "https://t.me/phaverdao";

export const NEXT_PUBLIC_GA_MEASUREMENT_ID = env
  .get("NEXT_PUBLIC_GA_MEASUREMENT_ID")
  .default("")
  .asString();

// Non-default should only be used for debug purposes as UI always mentions Lens Api as the dispatcher
export const NEXT_PUBLIC_DISPATCHER_TYPE = env
  .get("NEXT_PUBLIC_DISPATCHER_TYPE")
  .default("LensApi")
  .asEnum(["LensApi", "Phaver"]);

export const NEXT_PUBLIC_FIREBASE_APP_CHECK_ENABLED = env
  .get("NEXT_PUBLIC_FIREBASE_APP_CHECK_ENABLED")
  .default("false")
  .asBool();

export const NEXT_PUBLIC_FIREBASE_APP_CHECK_DEBUG_TOKEN = env
  .get("NEXT_PUBLIC_FIREBASE_APP_CHECK_DEBUG_TOKEN") // check devs-debug in 1pass
  .asString();

export const NEXT_PUBLIC_FIREBASE_APP_CHECK_TOKEN_HEADER_ENABLED = env
  .get("NEXT_PUBLIC_FIREBASE_APP_CHECK_TOKEN_HEADER_ENABLED")
  .asBool();

export const NEXT_PUBLIC_RECAPTCHA_SITE_KEY = env
  .get("NEXT_PUBLIC_RECAPTCHA_SITE_KEY")
  .default("")
  .required(NEXT_PUBLIC_FIREBASE_APP_CHECK_ENABLED)
  .asString();
