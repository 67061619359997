import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import {
  ThirdwebProvider,
  metamaskWallet,
  walletConnect,
} from "@thirdweb-dev/react";
import { appWithTranslation } from "next-i18next";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { Fonts } from "src/components/atoms/Fonts";
import { Layout } from "src/components/atoms/Layout";
import { Header } from "src/components/molecules/Header";
import { NEXT_PUBLIC_CHAIN_ID, NEXT_PUBLIC_RPC_URL } from "src/config";
import { SignInLensProvider } from "src/contexts/SignInLensContext";
import { client } from "src/lib/apollo";
import { theme } from "src/lib/theme";
import { CurrentUserProvider } from "src/providers/UserProvider";
import SEO from "../../next-seo.config";

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <DefaultSeo {...SEO} />
      <GoogleAnalytics trackPageViews />
      <ThirdwebProvider
        dAppMeta={{
          name: "Phaver",
          description: SEO.title,
          isDarkMode: true,
          logoUrl: "https://connect.phaver.com/logos/phaver-logo.svg",
          url: "https://phaver.com",
        }}
        supportedWallets={[metamaskWallet(), walletConnect()]}
        activeChain={NEXT_PUBLIC_CHAIN_ID}
        sdkOptions={{
          readonlySettings: {
            chainId: NEXT_PUBLIC_CHAIN_ID,
            rpcUrl: NEXT_PUBLIC_RPC_URL,
          },
        }}
      >
        <ApolloProvider client={client}>
          <ChakraProvider theme={theme}>
            <Fonts />
            <Layout>
              <CurrentUserProvider>
                <SignInLensProvider>
                  <Header />
                  <Component {...pageProps} />
                </SignInLensProvider>
              </CurrentUserProvider>
            </Layout>
          </ChakraProvider>
        </ApolloProvider>
      </ThirdwebProvider>
    </>
  );
};

export default appWithTranslation(App);
